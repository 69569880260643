import React from 'react';
import { Input, Icon, Table, SpaceBetween, Button } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import './AllocationGrid.css';
import { round } from 'lodash/math';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { getEntityDetailsKey, SURVEY_TYPE } from '../../../../common/constants/surveyType';
import { isReadOnly } from '../../utils/survey_page_utils';

const RenderCell = ({
  colIndex,
  rowEntity,
  colsEntity,
  entityGridValues,
  allocationType,
  entityForActivityAllocation,
  onInputChangeHandler,
  onBlurHandler,
  surveyDetails,
}) => {
  if (!colsEntity[colIndex]) return null;

  if (rowEntity.id === 'total') {
    const total =
      entityGridValues && entityGridValues.total ? parseFloat(entityGridValues.total) : 0;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {total.toFixed(2)}%
        {total !== 100 && <Icon name="status-warning" size="small" variant="error" />}
      </div>
    );
  }

  const cellValue =
    (colsEntity[colIndex] && entityGridValues && entityGridValues[rowEntity.id]) || '';

  const inputBoxId =
    allocationType === 'Activity'
      ? `${entityForActivityAllocation?.id}#${colsEntity[colIndex].id}#${rowEntity.id}`
      : `${colsEntity[colIndex].id}#${rowEntity.id}`;

  return (
    <div className="percent-input-box-area">
      <SpaceBetween direction="horizontal" size="xs" className="render-cell">
        <div className="percent-input-box">
          <Input
            key={inputBoxId}
            type="text"
            value={cellValue}
            onChange={({ detail }) =>
              onInputChangeHandler(detail.value, colsEntity[colIndex], rowEntity, inputBoxId)
            }
            disabled={isReadOnly(surveyDetails)}
            onBlur={() => onBlurHandler(colsEntity[colIndex], rowEntity)}
          />
        </div>
        <div className="percent-sign">%</div>
      </SpaceBetween>
    </div>
  );
};

RenderCell.propTypes = {
  colIndex: PropTypes.number.isRequired,
  rowEntity: PropTypes.object.isRequired,
  colsEntity: PropTypes.array.isRequired,
  entityGridValues: PropTypes.object.isRequired,
  allocationType: PropTypes.string.isRequired,
  entityForActivityAllocation: PropTypes.object,
  onInputChangeHandler: PropTypes.func.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

const AllocationGrid = props => {
  const {
    title,
    rowsEntity,
    colsEntity,
    gridValues,
    setGridValues,
    allocationType,
    surveyType,
    surveyDetails,
    entityForActivityAllocation,
    copiedValues,
    copiedColumn,
    setCopiedValues,
    derivedClientId,
  } = props;

  const resetCopiedValues = () => {
    setCopiedValues({}, undefined);
  };

  function getTotalForArray(values) {
    if (!values) {
      return '0';
    }
    return Object.keys(values)
      .reduce((partialSum, key) => {
        if (key === 'total') {
          return partialSum;
        }
        return partialSum + Number(values[key]);
      }, 0)
      .toString();
  }

  const createUpdatedGridObject = (colEntity, rowEntity, value) => {
    const newGridValues = {
      ...gridValues,
      [colEntity.id]: {
        ...gridValues[colEntity.id],
        [rowEntity.id]: round(Number(value), 1).toString(),
      },
    };
    newGridValues[colEntity.id].total = getTotalForArray(newGridValues[colEntity.id]);
    return newGridValues;
  };

  const onBlurHandler = (colEntity, rowEntity) => {
    const value = gridValues[colEntity.id] ? gridValues[colEntity.id][rowEntity.id] || 0 : 0;

    const newGridValues = createUpdatedGridObject(colEntity, rowEntity, value);
    setGridValues(newGridValues);
    resetCopiedValues();
  };

  const getCopiedColumnId = id => {
    return entityForActivityAllocation ? entityForActivityAllocation.id + id : id;
  };

  const onInputChangeHandler = (cellValue, colEntity, rowEntity, inputBoxId) => {
    if (Number.isNaN(Number(cellValue))) return;
    const value = cellValue || '0';
    if (Number(value) < 0) return;
    const inputElement = document.getElementById(inputBoxId);
    if (inputElement) {
      inputElement.style.borderColor = Number(value) > 100 ? 'red' : '#aab7b8';
    }
    const copiedColumnId = getCopiedColumnId(colEntity.id);
    if (copiedColumn === copiedColumnId) {
      resetCopiedValues();
    }

    const newGridValues = createUpdatedGridObject(colEntity, rowEntity, value);
    setGridValues(newGridValues);
  };

  const handleCopy = colId => {
    const newCopiedValues = {};
    rowsEntity.forEach(row => {
      if (row.id !== 'total') {
        newCopiedValues[row.id] = gridValues[colId] ? gridValues[colId][row.id] || '' : '';
      }
    });
    setCopiedValues(newCopiedValues, getCopiedColumnId(colId));
  };

  const handlePaste = colId => {
    if (!copiedValues || Object.keys(copiedValues).length === 0) return;

    const newGridValues = { ...gridValues };
    newGridValues[colId] = {};
    Object.keys(copiedValues).forEach(rowId => {
      if (copiedValues[rowId] !== '') {
        newGridValues[colId][rowId] = round(Number(copiedValues[rowId] || 0), 1).toString();
      }
    });
    newGridValues[colId].total = getTotalForArray(newGridValues[colId]);
    setGridValues(newGridValues);
  };

  const getEntityDetailsKeyComponent = entityDetail => {
    const [lastName, firstName] = entityDetail.name.split(',');
    switch (surveyType) {
      case SURVEY_TYPE.LABOR:
      default:
        return (
          <div>
            <strong>{firstName}</strong>
            <br />
            <strong>{lastName}</strong>
            <br />
            <small>{entityDetail.jobTitle}</small>
          </div>
        );
    }
  };

  const renderCopyPasteCell = colId => (
    <div className="copy-paste-cell">
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          variant="inline-link"
          onClick={() => handleCopy(colId)}
          disabled={isReadOnly(surveyDetails) || copiedColumn === getCopiedColumnId(colId)}
        >
          {copiedColumn === getCopiedColumnId(colId) ? 'Copied' : 'Copy'}
        </Button>
        <Button
          variant="inline-link"
          onClick={() => handlePaste(colId)}
          disabled={
            isReadOnly(surveyDetails) || !copiedColumn || copiedColumn === getCopiedColumnId(colId)
          }
        >
          Paste
        </Button>
      </SpaceBetween>
    </div>
  );

  const prepareColumns = () => {
    const fixedColumns = [
      {
        id: 'rowEntity',
        header: allocationType === 'Activity' ? 'Activities' : 'Projects',
        cell: rowEntity => rowEntity.name,
        sticky: 'left',
        minWidth: 174,
        maxWidth: 174,
      },
    ];
    if (
      allocationType === 'Activity' &&
      surveyType === 'LaborDual' &&
      derivedClientId === 'Radical'
    )
      fixedColumns.push({
        id: 'rowEntityDesc',
        header: 'Activity description',
        cell: rowEntity => rowEntity.description,
        sticky: 'left',
        minWidth: 480,
        maxWidth: 480,
      });

    return [
      ...fixedColumns,
      ...colsEntity.map(entity => ({
        id: allocationType === 'Activity' ? entity.id : getEntityDetailsKey(surveyType, entity),
        header: getEntityDetailsKeyComponent(entity),
        cell: rowEntity =>
          rowEntity.id === 'copyPaste' ? (
            renderCopyPasteCell(entity.id)
          ) : (
            <RenderCell
              key={entity.id}
              colIndex={colsEntity.indexOf(entity)}
              rowEntity={rowEntity}
              colsEntity={colsEntity}
              entityGridValues={gridValues[entity.id] || {}}
              allocationType={allocationType}
              entityForActivityAllocation={entityForActivityAllocation}
              onInputChangeHandler={onInputChangeHandler}
              onBlurHandler={onBlurHandler}
              surveyDetails={surveyDetails}
            />
          ),
        minWidth: 144,
      })),
    ];
  };

  const columns = prepareColumns();

  const items = [
    {
      id: 'copyPaste',
      name: 'Copy / Paste',
    },
    ...rowsEntity.map(rowEntity => ({
      id: rowEntity.id,
      name: <strong>{rowEntity.name}</strong>,
      description: rowEntity.description,
    })),
    {
      id: 'total',
      name: <strong>Allocated</strong>,
    },
  ];

  return (
    <Table
      header={title}
      columnDefinitions={columns}
      items={items}
      variant="borderless"
      stickyHeader
      stickyColumns={{ first: 1, last: 0 }}
      trackBy="id"
      wrapLines
    />
  );
};

AllocationGrid.propTypes = {
  title: PropTypes.any,
  rowsEntity: PropTypes.array.isRequired,
  colsEntity: PropTypes.array.isRequired,
  allocationType: PropTypes.string.isRequired,
  gridValues: PropTypes.object.isRequired,
  setGridValues: PropTypes.func.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  entityForActivityAllocation: PropTypes.object,
  copiedValues: PropTypes.object,
  copiedColumn: PropTypes.string,
  setCopiedValues: PropTypes.func.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

export default connect(mapStateToProps, null)(AllocationGrid);
