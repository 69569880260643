import React, { useState } from 'react';
import {
  Box,
  Cards,
  ExpandableSection,
  Header,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import { get, size, filter } from 'lodash';
import PropTypes from 'prop-types';
import MultiSelectCards from './MultiSelectCards';
import getDocById from '../../../../../common/components/doc_link/utils/getDocById';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import { isReadOnly } from '../../../utils/survey_page_utils';

const Activity = ({ state, setState, pageContents, surveyDetails }) => {
  const [areDefaultActivitiesVisible, setDefaultActivitiesVisible] = useState(false);
  const [showDescription, setShowDescription] = React.useState(true);
  const surveyType = get(surveyDetails, 'surveyType', '');
  const selectActivitiesHandler = ({ detail: { selectedItems } }) => {
    setState({
      key: 'Activity',
      value: {
        ...state.Activity,
        data: {
          ...state.Activity.data,
          selectedActivities: selectedItems,
        },
      },
    });
  };

  const listOfActivities = filter(get(pageContents, 'Activity.activityList', []), ob => {
    return ob.isActivityDefault === 'No';
  });

  const defaultList = filter(get(pageContents, 'Activity.activityList', []), ob => {
    return ob.isActivityDefault === 'Yes';
  });

  const filterDescriptionFromActivities = list => {
    return list.map(element => {
      return { name: element.name, id: element.id, isActivityDefault: element.isActivityDefault };
    });
  };

  return (
    <SpaceBetween size="l">
      <ExpandableSection
        variant="container"
        disableContentPaddings
        header={
          <Header
            counter={`(${size(defaultList)})`}
            variant="h2"
            info={
              <DocLink
                pdf={getDocById(process.env.REACT_APP_FAQ_FILE_ID, surveyType)}
                text="Why are some activities pre-selected?"
                variant="info"
              />
            }
          >
            Default activities
          </Header>
        }
        onChange={({ detail }) => setDefaultActivitiesVisible(detail.expanded)}
      />
      <MultiSelectCards list={defaultList} isVisible={areDefaultActivitiesVisible} />
      <Cards
        header={
          <Header
            counter={`(${size(get(state, 'Activity.data.selectedActivities', []))})`}
            variant="h2"
            actions={
              <Toggle
                onChange={({ detail }) => setShowDescription(detail.checked)}
                checked={showDescription}
              >
                Show description
              </Toggle>
            }
          >
            Select activities
          </Header>
        }
        items={
          showDescription ? listOfActivities : filterDescriptionFromActivities(listOfActivities)
        }
        trackBy="id"
        selectionType="multi"
        loadingText="Loading activities"
        selectedItems={get(state, 'Activity.data.selectedActivities', [])}
        cardsPerRow={[{ cards: 1 }]}
        isItemDisabled={() => isReadOnly(surveyDetails)}
        cardDefinition={{
          header: e => e.name,
          sections: [
            {
              id: 'description',
              content: e => e.description,
            },
          ],
        }}
        empty={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No activities to display.
            </Box>
          </Box>
        }
        onSelectionChange={selectActivitiesHandler}
        entireCardClickable
      />
    </SpaceBetween>
  );
};
Activity.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

export default Activity;
